/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

//add in css for bootstrap elements we want
//instead of "./node_modules/bootstrap/dist/css/bootstrap.min.css",  in angular.json
// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// Optional
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/utilities";

@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px!important;
    }
}

body {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}